import React from "react";
import Header from "./Header/Header";
import heart from '../../assets/heart.png';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import calories from '../../assets/calories.png';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter'



import './Hero.css'

const Hero = ()=>{
     const transition = {type:'spring',duration:3};
     const mobile = window.innerWidth<=768 ? true : false;
    return(
<div className="hero">
     <div className="blur hero-blur"></div>
            <div className="left-h"> 
            <Header/>

            {/* the best ad */}
            <div className="the-best-ad">
                <motion.div
                 initial={{left:mobile? '165px' : '238px'}}
                 whileInView={{left:'8px'}}
                 transition={transition}>
                   
                </motion.div>
                <span> The best fitness club in the twon </span>
            </div>
            {/* the best ad */}

            {/* hero Handling */}
            <div className="hero-text">
                <div>
                     <span className="stroke-text">
                        Shape
                     </span>

                     <span>
                        Your
                     </span>

                </div>


                <div>
                     <span>
                          Ideal body 

                     </span>
                </div>

                <div>
                     <span>
                     In here we will help you to shape and build your ideal body and live up your life to fullest
                     </span>
                

                </div>

            </div>
            {/* hero handling */}

            {/* +140 */}

            <div className="figures">
                 <div>
                      <span>
                      <NumberCounter end={140} start={100} delay='4' preFix='+'/>
                      </span>
                      <span>
                      EXPERT COACHES
                      </span>
                 </div>
                 <div>
                       <span>
                       <NumberCounter end={978} start={800} delay='4' preFix='+'/>
                       </span>
                       <span>
                       MEMBERS JOINED
                       </span>
                 </div>
                 <div>
                         <span>
                         <NumberCounter end={50} start={0} delay='4' preFix='+'/>
                         </span>
                         <span>
                         FITNESS PROGRAMS
                        </span>
                 </div>
            </div>
            {/* +140 */}

            {/* hero buttons */}

            <div className="hero-buttons">
                <buttons className="btn"> Get started</buttons>
                <buttons className="btn"> Learn more </buttons>

            </div>

             {/* hero buttons */}

  { /* نهاية div left */}   </div>

            
            <div className="right-h"> 
                         <button className="btn"> join Now </button>

                         <motion.div
                         initial={{right:'-1rem'}}
                         whileInView={{left:'4rem'}}
                         transition={transition}
                          className="heart-rate">
                         <img src={heart} alt="Error"/>
                         <span>heart Rate </span>
                         <span>116 bpm</span>
                    </motion.div>

                    <img src={hero_image} alt="Error" className="hero-image"/>
                    <motion.img  
                    initial={{right:'11rem'}}
                    whileInView={{right:'20rem'}}
                    transition={transition}
                     src={hero_image_back} alt="Error" className="hero-image-back"/>
                    <motion.div

                         initial={{right:'37rem'}}
                         whileInView={{right:'28rem'}}
                         transition={transition}
                     className="calories">
                         <img src={calories} alt="Error" />
                         <div>
                              <span>Calories burned</span>
                              <span> 220 kcal </span>
                         </div>
                         


                    </motion.div>
            
            
            
            </div>



{/* div الرئيسية */}</div>
    )
}
export default Hero ;